<template>
<div class="statusList" >

  <mini-loader v-if="load" />
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5 class="text-dark">Статусы</h5>
      <a @click="createItem">
        <span class=" p-1">
          <button type="button" class="btn btn-success">Добавить
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 20 20">
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
            </svg>
          </button>

        </span>
      </a>
    </div>
    <table class="table table-striped" id="statusList">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Наименование</th>
          <th scope="col">Порядок</th>
          <th scope="col">Тип</th>
          <th scope="col">Цвет</th>
          <th scope="col">Действие</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(value, index) in data">
          <th scope="row">{{index+1}}</th>
          <td>{{value.title}}</td>
          <td>{{value.order}}</td>
          <td v-if="value.access_to_delete==0"> <span class="btn btn-danger">Статический</span> </td> <td v-else> <span class="btn btn-success">Динамический</span> </td>
          <td> <p style="padding: 10px; border-radius: 4px" :style="{'background': value.color}"></p> </td>
          <td>
            <a  @click="showItem(value)">
              <span class="border rounded border-primary m-1 p-2 text-primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
              </span>
            </a>
            <a @click="editItem(value)" v-if="value.access_to_delete">
              <span class="border rounded border-success m-1 p-2 text-success">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                </svg>
              </span>
            </a>
            <a @click="deleteItem(index, value)" v-if="value.access_to_delete">
              <span class="border rounded border-danger m-1 p-2 text-danger">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <p class="m-10 text-dark text-center" style="border: 1px solid #DFE1E4; padding: 10px">1 - {{data.length}} данных  из {{totalResults}}</p>
  </div>

<!-- Show Modal -->
<a-modal v-model="visibleShowModal" title="Детали"  footer="">
  <table class="table table-striped" >
    <tbody>
      <tr>
        <td>ID</td>
        <td>{{item.id}}</td>
      </tr>
      <tr>
        <td>Наименование</td>
        <td>{{item.title}}</td>
      </tr>
      <tr>
        <td>Порядок</td>
        <td>{{item.order}}</td>
      </tr>
      <tr>
        <td>Тип</td>
        <td v-if="item.access_to_delete"><span class="btn btn-success">Динамический</span></td> <td v-else><span class="btn btn-danger">Статический</span></td>
      </tr>
      <tr>
        <td>Цвет</td>
        <td><span style="padding: 5px 30px; border-radius: 4px;" :style="{'background': item.color}"></span></td>
      </tr>
    </tbody>
  </table>
</a-modal>

<!-- Edit modal -->
<a-modal v-model="visibleEditModal" title="Изменить данные"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="item.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Порядок</label>
    <input type="text" class="form-control" v-model="item.order" :class="{'is-invalid': validateErrors.order}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.order"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Цвет</label>
    <br>
    <v-swatches v-model="item.color" ></v-swatches>
    <div class="invalid-feedback" v-for="value in validateErrors.color"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="updateItem">Изменить</button>
  </div>
</a-modal>

<!-- Create Modal -->
<a-modal v-model="visibleCreateModal" title="Добавить новую"  footer="">
  <div class="form-group">
    <label for="exampleInputEmail">Наименование</label>
    <input type="text" class="form-control" v-model="newItem.title" :class="{'is-invalid': validateErrors.title}" placeholder="Наименование">
    <div class="invalid-feedback" v-for="value in validateErrors.title"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Порядок</label>
    <input type="text" class="form-control" v-model="newItem.order" :class="{'is-invalid': validateErrors.order}" placeholder="Порядок">
    <div class="invalid-feedback" v-for="value in validateErrors.order"> {{value}} </div>
  </div>
  <div class="form-group">
    <label for="exampleInputEmail">Цвет</label>
    <ColourPicker
        v-model="newItem.color"
        :value="newItem.color"
        no-input
        picker="sketch" />
    <div class="invalid-feedback" v-for="value in validateErrors.color"> {{value}} </div>
  </div>
  <div class="d-flex">
    <button type="submit" class="ml-auto btn btn-primary" @click="storeItem">Добавить</button>
  </div>
</a-modal>

<!-- Delete modal -->
<a-modal v-model="visibleDeleteModal" title="Подтверждение на удаление" ok-text="Удалить" cancel-text="Отменить" @ok="destroyItem">
  <h6 style="color: red">Точно хотите безвозвратно удалить ?</h6>
</a-modal>
</div>


</template>

<script>
import axios from 'axios'
import ColourPicker from 'vue-colour-picker'
import VSwatches from 'vue-swatches'

import "vue-swatches/dist/vue-swatches.css"

const data = '';
export default {
  name : 'Branches',
  components: {
    ColourPicker,
    VSwatches
  },
  data(){
    return {
      data,
      item: {
        title: '',
        order: '',
        color: ''
      },
      newItem: {
        title: '',
        order: '',
        color: ''
      },
      validateErrors: [],

      load: false,
      visibleShowModal: false,
      visibleEditModal: false,
      visibleCreateModal: false,
      visibleDeleteModal: false,
      editCountryRegionCity: false,

      totalResults: 0,
      old_height: 0,
      last_page: 1,
      index: ''


    }
  },
  mounted(){
    this.load = true;
    axios.get('/status').then(response => {
      if(response.status == 200){
        this.data = response.data.data;
        this.load = false;
        this.totalResults = response.data.total
        this.last_page = response.data.last_page
      }else if(response.status == 204){
        this.load = false;
        this.$message.error(
              'Филиалов не найдено',
              3,
        );
      }
    }).catch(error => {
      this.load = false;
      this.$message.error(
            'Филиалов не найдено',
            3,
      );
    });

    window.onscroll = () => {
        let height = document.getElementById('statusList').clientHeight
        if ((window.innerHeight + document.documentElement.scrollTop) >= height) {
          if(this.old_height!=height){
            this.old_height = height;
            let limit = this.data.length/this.$store.state.paginateCount + 1;
            if(limit <= this.last_page){
                this.load = true;
                axios.get('/status', {params: {page: limit}}).then(response => {
                  if(response.status == 200){
                      this.totalResults = response.data.total;
                      this.data = this.data.concat(response.data.data);
                      this.load = false
                  }else if(response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Офисов не найдено',
                      3
                    );
                  }
                }).catch(error => {
                  if(error.response.status == 500){
                    this.load = false;
                    this.$message.error(
                      'Ошибка сервера',
                      3
                    );
                  }else if(error.response.status == 204){
                    this.load = false;
                    this.$message.error(
                      'Офисов не найдено',
                      3
                    );
                  }else{
                    this.load = false
                  }
                });

            }
          }
        }
    };

  },
  methods: {
    showItem(value){
      this.item = value;
      this.visibleShowModal = true;
    },
    editItem(value){
      this.item = value;
      console.log(this.item)
      this.visibleEditModal = true;
    },
    createItem(){
      this.visibleCreateModal = true
    },
    deleteItem(index, value){
      this.index = index;
      this.item = value;
      this.visibleDeleteModal = true;
    },

    updateItem(){
      this.load = true;
      axios.put('/status/'+this.item.id, this.item)
      .then(response => {
        if(response.status == 200){
          this.load = false;
          this.visibleEditModal = false
          this.$message.success(
                'Успешно изменено',
                3,
          );
        }else if(response.status == 404){
          this.$message.error(
                response.data.error,
                3,
          );
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 422){
          this.load = false;
          this.$message.error(
                error.response.data.message,
                3,
          );
          this.validateErrors = error.response.data.errors
        }else if(error.response.status == 404){
          this.load = false;
          this.$message.error(
                error.response.data.error,
                3,
          );
          this.load = false;
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
          this.load = false;
        }
      });
    },
    storeItem(){
      this.load = true;
      axios.post('/status', this.newItem)
      .then(response => {
        if(response.status == 201){
          this.visibleCreateModal = false;
          this.load = false;
          this.newItem.title = '';
          this.$message.success(
                'Успешно добавлено',
                3,
          );
        }else{
          this.load = false;
        }
      }).catch(error => {
        if(error.response.status == 500){
          this.load = false;
          this.$message.error(
            'Ошибка сервера',
            3
          );
        }else if(error.response.status == 422){
          this.validateErrors = error.response.data.errors
          this.load = false
          this.$message.error(
            error.response.data.message,
            3
          )
        }
      });
    },
    destroyItem(){
      axios.delete('/status/'+this.item.id).then(response => {
         if(response.status==200){
          this.$message.success(
                response.data.success,
                3,
          );
          this.data.splice(this.index, 1)
          this.totalResults--;
        }
      }).catch(error => {
        if(error.response.status==400){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 404){
          this.$message.error(
                error.response.data.error,
                3,
          );
        }else if(error.response.status == 500){
          this.$message.error(
                'Ошибка сервера',
                3,
          );
        }
      }).finally(() => {
          this.visibleDeleteModal = false
      });
    },



  }
}
</script>
<style>
  .ant-modal-mask{
    z-index: 2000 !important;
  }
  .ant-modal-wrap{
    z-index: 2000 !important;
  }
  .ant-message{
    z-index: 2010 !important
  }
  .ant-message-notice{
    z-index: 2020 !important;
  }
  .ant-message-notice-content{
    z-index: 2020 !important;
  }
  .ant-select-dropdown{
    z-index: 2000 !important;
  }
</style>
